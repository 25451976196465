{
  "forbidden-view": {
    "change-account": "Changer de compte",
    "desc": "Besoin de demander l’accès? Décris ci-dessous pourquoi tu souhaiterais avoir accès à l’application.",
    "request-access": "Demander l’accès",
    "request-sent": "Ta demande d'habilitation a été envoyée.",
    "textarea-placehoder": "Entre ta description (500 caractères max)",
    "title": "Tu n’es pas autorisé à accéder à cette application"
  },
  "nomenclatures": {
    "PRX": "Nomenclature proxi",
    "STD": "Nomenclature retail"
  },
  "navbar": {
    "Dashboard": "Dashboard",
    "Cession":"Cessions marchandise",
    "TPL": "TPL",
    "BusinessVolume":"Chiffre d'affaires"
  },
  "businessVolume": {
    "title": "Chiffre d'affaires"
  },
  "cession": {
    "title": "Cession Marchandise"
  },
  "businessVolumeByVATGrid": {
    "title": "Chiffre d'affaires par Taux de TVA",
    "total": "Total",
    "period": "Période",
    "vat": "TVA",
    "vat-amount": "Montant TVA",
    "tax-incl-turnover": "CA TTC"
  },
  "businessVolumeByVATNom": {
    "title": "Chiffre d'affaires ventilé par Taux de TVA et Marché / Famille",
    "title-without-family": "Chiffre d'affaires ventilé par Taux de TVA et Marché",
    "vat-rate": "Taux TVA",
    "market": "Marché",
    "marketLabel": "Libellé marché",
    "family": "Famille",
    "familyLabel": "Libellé famille",
    "tax-excl-turnover": "CA HT",
    "collected-vat": "TVA collectée",
    "tax-incl-turnover": "CA TTC",
    "total": "Total"
  },
  "vatByVatRateGrid": {
    "total": "Grand total",
    "collected-vat": "Taux TVA / TVA Collectée",
    "cession-vat": "Taux TVA / TVA Cession"
  },  
  "tplByVATNom": {
    "title": "TABLEAU TPL - Ventilé par Marché",
    "statement": "N° Relevé Interne",
    "sector": "Secteur",
    "market": "Marché",
    "marketLabel": "Libellé marché",
    "vatRate": "Taux de TPL",
    "total": "Total",
    "tplAmountWithoutTax": "Montant TPL HT",
    "tplVATAmount": "TVA TPL",
    "tplAmountWithTax": "Montant TPL TTC"
  },
  "cessionByVATNom": {
    "title": "CESSION PAR TAUX DE TVA - Ventilé par Marché",
    "statement": "N° Relevé Interne",
    "sector": "Secteur",
    "market": "Marché",
    "marketLabel": "Libellé Marché",
    "vatRate": "Taux de TVA",
    "total": "Total",
    "regieCession": "Cession + Régie",
    "ht": "HT",
    "vat": "TVA",
    "vignette": "Vignette",
    "cessionAmountWithTax": "Montant Cession TTC",
    "packagingAmountWithoutTax": "Montant Emballage HT"
  },
  "cessionAmountBySector": {
    "title": "Montant Cession TTC",
    "subTitle": "par secteur"
  },
  "packageAmountBySector": {
    "title": "Montant Emballages",
    "subTitle": "par secteur"
  },
  "cessionDivision": {
    "title": "Répartition Cession"
  },
  "total": "TOTAL",
  "selectedPeriod": "Période sélectionnée",
  "vat": "TVA",
  "kpis": {
    "tax-incl-turnover": "CA TTC (SDV + Essence)",
    "tax-not-incl-turnover": "CA HT",
    "collected-vat": "TVA Collectée",
    "cession-control": "Cession + Régie",
    "cession-control-info": "Cession + Régie",
    "cession-vat": "TVA Cession",
    "cession-vat-info": "TVA Cession",
    "packaging-amount": "Montant Emballage HT",
    "packaging": "Emballages",
    "packaging-info": "Emballages",
    "df-thumbnails": "Vignettes HT",
    "df-thumbnails-info": "Vignettes HT",
    "vat-thumbnails": "TVA Vignettes",
    "vat-thumbnails-info": "TVA Vignettes",
    "tax-incl-cession": "Cession TTC",
    "tax-incl-cession-info": "Cession TTC",
    "gas-turnover": "CA TTC (Essence)"
  },
  "cessionByWeeksLineChart": {
    "title": "Montant Cession TTC / N° semaine",
    "pgc": "PGC",
    "frais": "Frais",
    "nonAl": "Non Al"
  },
  "businessVolumeByWeeksLineChart": {
    "title": "CA TTC / N° semaine"
  },
  "cessionBySectorsPieChart": {
    "titleOne": "Part des",
    "titleTwo": "Montants Cession TTC",
    "pgc": "PGC",
    "frais": "Frais",
    "nonAl": "Non Al"
  },
  "businessVolumeBySectorPieChart": {
    "title": "Part du CA TTC par secteur"
  },
  "dashboard": {
    "bv-cession-title": "Chiffre d'affaires et cessions"
  },
  "cessionTurnoverLineChart": {
    "title": "Chiffre d'affaires TTC (hors station) cumul sur l'année",
    "cession": "Cession",
    "turnover": "CA TTC"
  },
  "coming-soon": "BIENTOT",
  "exportVATTitle": "ExportCAParTVA.csv",
  "exportError": "Une erreur s'est produite lors de l'exportation des données et/ou nous n'avons pas de données pour les filtres selectionnés.",
  "market": "ID Marché",
  "marketLabel": "Libellé Marché",
  "family": "ID Famille",
  "familyLabel": "Libellé Famille",
  "startDate": "Date de début",
  "endDate": "Date de fin",
  "site": "Site",
  "siteLabel": "Libellé Site",
  "vatRate": "Taux de TVA",
  "vatRateAmount": "Montant TVA",
  "salesAmountWithoutTax": "CA HT",
  "salesAmountWithTax": "CA TTC",
  "loading": "Chargement...",
  "export": "Exporter",
  "seeMore": "Voir les détails",
  "printPeriod": "Période du {start} au {end}",
  "login-to": "Se connecter à "
}
